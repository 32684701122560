<template>
	<div>
		<h1>Buy-In Offers Manager</h1>
		<div v-if="serverBusy" id="server-activity">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<button id="create-buy-in" title="Create buy-in offer" class="btn" @click="createBuyIn()">Create buy-in offer</button>
		<div id="paging">
			<span class="btn obsolete">
				<label for="show-obsolete">Show Obsolete</label>
				<input id="show-obsolete" type="checkbox" v-model="showObsolete" />
			</span>
			<button id="refresh" title="Refresh buy-ins list" class="btn" @click="getBuyIns(true)">Refresh</button>
			<label for="limitOptions">Limit List</label>
			<select title="Set Limit" v-model="limit" id="limitOptions">
				<option v-for="(item, index) in limitOptions" :key="index" :value="item.value">{{ item.text }}</option>
			</select>
			<button :disabled="serverBusy" class="prev-button" type="button" title="Previous" @click="previousPage()">Previous</button>
			<button :disabled="serverBusy" class="next-button" type="button" title="Next" @click="nextPage()">Next</button>
			<span :currentPage="currentPage">page {{ currentPage }}</span>
		</div>
		<BuyInsTable
			v-if="buyIns.length > 0"
			:adminState="adminState"
			:buyIns="buyIns"
			:inGameCurrencyTool="inGameCurrencyTool"
			:systemCurrencyTool="systemCurrencyTool"
		/>
		<h2 v-else>No {{ showObsolete ? "Obsolete" : "Active" }} Buy-ins Found</h2>
		<transition name="fade">
			<component :is="currentModal" :adminState="adminState" :serverStatus="serverStatus" :systemCurrencyTool="systemCurrencyTool" />
		</transition>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import BuyInsTable from "@/components/BuyInsTable";
import CreateBuyInOffer from "@/components/CreateBuyInOffer";

export default {
	name: "BuyInsManager",
	components: {
		BuyInsTable,
		CreateBuyInOffer,
	},
	props: {
		adminState: Object,
		serverStatus: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			currentModal: null,
			currentPage: 1,
			offset: 0,
			limit: 10,
			isLastPage: false,
			// isObsolete: null,
			showObsolete: false,
			buyIns: [],
		};
	},
	watch: {
		limit() {
			this.offset = 0;
			this.currentPage = 1;
			this.getBuyIns();
		},
		showObsolete() {
			this.offset = 0;
			this.currentPage = 1;
			this.getBuyIns();
		},
	},
	created() {
		this.getBuyIns();
		this.eventBus.on("updateBuyInList", () => {
			this.getBuyIns();
		});
		this.eventBus.on("closeCreateOfferModal", () => {
			this.currentModal = null;
		});
		onBeforeUnmount(() => {
			this.eventBus.off("updateBuyInList");
			this.eventBus.off("closeCreateOfferModal");
		});
	},
	mounted() {},
	methods: {
		createBuyIn() {
			this.currentModal = "CreateBuyInOffer";
		},
		async getBuyIns(refresh = false) {
			this.busyText = `Loading ${this.showObsolete ? "Obsolete" : "Active"} Buy-In Offers`;
			this.serverBusy = true;

			if (refresh) {
				this.offset = 0;
				this.currentPage = 1;
			}

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/offer", this.rabbitsfootHostUrl);

			let params = requestUrl.searchParams;
			if (this.offset) params.set("offset", this.offset);
			params.set("isObsolete", this.showObsolete);

			// get one more item than requested to see if a second page exists
			if (this.limit) params.set("limit", Number(this.limit) + 1);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				if (Array.isArray(dataJson) && dataJson.length === 0) {
					this.status.warn = true;
					this.status.message = `Server returned code ${fetchStatus.code} but returned an empty list.`;
					this.eventBus.emit("updateStatus", this.status);
					this.status.warn = false;
				}

				this.isLastPage = dataJson?.length <= this.limit;
				// remove extra item if a next page exists
				if (!this.isLastPage) dataJson.pop();

				if (this.showObsolete) {
					this.buyIns = dataJson.sort((a, b) => b.endDate.localeCompare(a.endDate));
				} else {
					this.buyIns = dataJson.sort((a, b) => b.priority - a.priority);
					this.buyIns.forEach((buyIn) => {
						let buyInExpired = new Date(buyIn.endDate).toISOString().localeCompare(new Date().toISOString());
						buyIn.isExpired = buyInExpired === -1 ? true : false;
					});
				}

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		previousPage() {
			if (this.currentPage == 1) return;
			this.currentPage--;
			this.offset = this.offset - this.limit;
			this.getBuyIns();
		},
		nextPage() {
			if (this.isLastPage || this.buyIns.length < this.limit) return;
			this.offset = this.offset + this.limit;
			this.currentPage++;
			this.getBuyIns();
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#server-activity {
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgb(0 0 0 / 70%);
	backdrop-filter: blur(6px);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: grid;
	align-content: center;
	z-index: 2;
}

button#create-buy-in {
	display: block;
	margin: auto auto 15px;
}

.obsolete {
	font-size: 13.3333px;
	padding: 0;
}

.obsolete,
.obsolete * {
	display: flex;
	cursor: pointer;
	align-content: center;
}
</style>
