<template>
	<div id="create-modal">
		<div v-if="!!serverBusy" id="server-activity-loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<div id="create-container">
			<fieldset>
				<legend>Create Buy-In Offer</legend>
				<p class="yellow-text" style="text-align: center">
					Once a buy-in is created it can not be edited.<br />You can only set it to obsolete.
				</p>
				<div class="label-input">
					<label for="offer-name" title="name">Offer Name</label>
					<input type="text" id="offer-name" name="Offer Name" placeholder="Offer Name" title="Offer Name" v-model="name" />
				</div>
				<div class="label-input">
					<label
						for="regular-coc"
						:title="`Regular Credit (${systemCurrencyTool.currencyInfo.symbol}) per ${systemCurrencyTool.formatFromAU(
							systemCurrencyTool.currencyInfo.auPerCurrencyUnit
						)} cash`"
						>Regular Credit ({{ systemCurrencyTool.currencyInfo.symbol }}) per
						{{ systemCurrencyTool.formatFromAU(systemCurrencyTool.currencyInfo.auPerCurrencyUnit) }}
						cash</label
					>
					<input
						type="number"
						id="regular-coc"
						name="regular-coc"
						:placeholder="`Regular Credit (${systemCurrencyTool.currencyInfo.symbol}) per ${systemCurrencyTool.formatFromAU(
							systemCurrencyTool.currencyInfo.auPerCurrencyUnit
						)} cash`"
						:title="`Regular Credit (${systemCurrencyTool.currencyInfo.symbol}) per ${systemCurrencyTool.formatFromAU(
							systemCurrencyTool.currencyInfo.auPerCurrencyUnit
						)} cash`"
						v-model="regularCashCOC"
					/>
				</div>
				<div class="label-input">
					<label
						for="promo-coc"
						:title="`Promo Credit (${systemCurrencyTool.currencyInfo.symbol}) per ${systemCurrencyTool.formatFromAU(
							systemCurrencyTool.currencyInfo.auPerCurrencyUnit
						)} cash`"
						>Promo Credit ({{ systemCurrencyTool.currencyInfo.symbol }}) per
						{{ systemCurrencyTool.formatFromAU(systemCurrencyTool.currencyInfo.auPerCurrencyUnit) }}
						cash</label
					>
					<input
						type="number"
						id="promo-coc"
						name="promo-coc"
						:placeholder="`Promo Credit (${systemCurrencyTool.currencyInfo.symbol}) per ${systemCurrencyTool.formatFromAU(
							systemCurrencyTool.currencyInfo.auPerCurrencyUnit
						)} cash`"
						:title="`Promo Credit (${systemCurrencyTool.currencyInfo.symbol}) per ${systemCurrencyTool.formatFromAU(
							systemCurrencyTool.currencyInfo.auPerCurrencyUnit
						)} cash`"
						v-model="promoCashCOC"
					/>
				</div>
				<div class="label-input">
					<label for="minimum" :title="`Minimum Cash Buy-In ${systemCurrencyTool.currencyInfo.symbol}`"
						>Minimum Cash Buy-In {{ systemCurrencyTool.currencyInfo.symbol }}</label
					>
					<input
						type="number"
						id="minimum"
						name="minimum"
						:placeholder="`Minimum Cash Buy-In ${systemCurrencyTool.currencyInfo.symbol}`"
						:title="`Minimum Cash Buy-In ${systemCurrencyTool.currencyInfo.symbol}`"
						v-model="minimumCOC"
					/>
				</div>
				<div class="label-input">
					<label for="maximum" :title="`Maximum Cash Buy-In ${systemCurrencyTool.currencyInfo.symbol}`"
						>Maximum Cash Buy-In {{ systemCurrencyTool.currencyInfo.symbol }}</label
					>
					<input
						type="number"
						id="maximum"
						name="maximum"
						:placeholder="`Maximum Cash Buy-In ${systemCurrencyTool.currencyInfo.symbol}`"
						:title="`Maximum Cash Buy-In ${systemCurrencyTool.currencyInfo.symbol}`"
						v-model="maximumCOC"
					/>
				</div>
				<div class="label-input">
					<label for="priority" title="Priority">Priority</label>
					<input type="number" id="priority" name="priority" placeholder="Priority" title="Priority" v-model="priority" />
				</div>
				<div class="label-input">
					<label for="start-date" title="Start Date">Start Date</label>
					<input
						type="datetime-local"
						id="start-date"
						:min="startMin"
						name="start-date"
						placeholder="Start Date"
						title="Start Date"
						v-model="startDate"
					/>
				</div>
				<div class="label-input">
					<label for="end-date" title="End Date">End Date</label>
					<input
						type="datetime-local"
						id="end-date"
						name="end-date"
						:min="endMin"
						:max="endMax"
						placeholder="End Date"
						title="End Date"
						v-model="endDate"
					/>
				</div>
				<div class="label-input">
					<label for="cashier-allowed" title="Cashier Allowed">Cashier Allowed</label>
					<input
						type="checkbox"
						id="cashier-allowed"
						name="cashier-allowed"
						placeholder="Cashier Allowed"
						title="Cashier Allowed"
						v-model="cashierAllowed"
					/>
				</div>
				<div class="label-input">
					<label for="mru-allowed" title="MRU Allowed">MRU Allowed</label>
					<input type="checkbox" id="mru-allowed" name="mru-allowed" placeholder="MRU Allowed" title="MRU Allowed" v-model="mruAllowed" />
				</div>
				<div class="label-input">
					<label for="web-allowed" title="Web Allowed">Web Allowed</label>
					<input type="checkbox" id="web-allowed" name="web-allowed" placeholder="Web Allowed" title="Web Allowed" v-model="webAllowed" />
				</div>
				<div class="button-group">
					<button class="btn" type="button" @click="createBuyInOffer()" title="Create New Casino">Create New Offer</button>
					<button type="button" class="btn" @click="closeModal()" :title="closeButtonText">{{ closeButtonText }}</button>
				</div>
			</fieldset>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "CreateBuyInOffer",
	props: {
		adminState: Object,
		serverStatus: Boolean,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			timeZoneOffset: new Date().getTimezoneOffset() / 60,
			body: document.querySelector("body"),
			serverBusy: false,
			busyText: "",
			closeButtonText: "Close",
			buyInOffer: {},
			name: "",
			regularCashCOC: 0,
			promoCashCOC: 0,
			minimumCOC: 0,
			maximumCOC: 0,
			startDate: "",
			endDate: "",
			startMin: null,
			startMax: this.startDate,
			endMin: null,
			endMax: null,
			priority: 0,
			cashierAllowed: false,
			mruAllowed: false,
			webAllowed: false,
		};
	},
	watch: {
		startDate() {
			this.endMin = this.startDate;
		},
		endDate() {
			this.startMax = this.endDate;
		},
	},
	created() {
		let start = new Date();
		start = new Date(start.setHours(start.getHours() - this.timeZoneOffset)).toISOString();
		let end = new Date();
		end = new Date(end.setHours(end.getHours() - this.timeZoneOffset)).toISOString();
		this.startDate = start.slice(0, 16);
		this.endDate = end.slice(0, 16);
		onBeforeUnmount(() => {
			this.body.onkeydown = null;
		});
	},
	mounted() {
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") this.closeModal();
		};
	},
	methods: {
		throwDateTimeError(inputName) {
			this.status.message = `${inputName} is not complete. Must have both the date and time or be left unset/empty.`;
			this.status.ok = false;
			this.eventBus.emit("updateStatus", this.status);
		},
		async createBuyInOffer() {
			let dateErrState = false;

			if (document.getElementById("start-date").validity.badInput) {
				this.throwDateTimeError("Start Date");
				dateErrState = true;
			}

			if (document.getElementById("end-date").validity.badInput) {
				this.throwDateTimeError("End Date");
				dateErrState = true;
			}

			if (dateErrState) return false;

			switch (true) {
				case !this.name:
					this.status.message = "Buy-in offer must have a name.";
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					return;
				case this.regularCashCOC < 0:
					this.status.message = "Regular Au per cash unit cannot be a negative number.";
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					return;
				case this.promoCashCOC < 0:
					this.status.message = "Promo Au per cash unit cannot be a negative number.";
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					return;
				case !this.minimumCOC > 0 || !this.maximumCOC > 0:
					this.status.message = "Minimum and maximum must be greater than 0.";
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					return;
				case !this.priority > 0:
					this.status.message = "Priority must be greater than 0.";
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					return;
			}

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			this.busyText = "Saving";
			this.serverBusy = true;
			this.status.ok = true;

			let body = {
				Name: this.name,
				CreditRegularAuPerCashCOC: this.regularCashCOC * this.systemCurrencyTool.currencyInfo.auPerCurrencyUnit,
				CreditPromoAUPerCashCOC: this.promoCashCOC * this.systemCurrencyTool.currencyInfo.auPerCurrencyUnit,
				MinimumCOC: this.minimumCOC,
				MaximumCOC: this.maximumCOC,
				StartDate: this.startDate ? this.formatDateTimeISOString(this.startDate, false) : null,
				EndDate: this.endDate ? this.formatDateTimeISOString(this.endDate, false) : null,
				Priority: this.priority ,
				CashierAllowed: this.cashierAllowed,
				MruAllowed: this.mruAllowed,
				WebAllowed: this.webAllowed,
			};

			let requestUrl = new URL("/api/v1/offer/", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.status.message = `${dataJson.name} has been created.`;
				this.status.code = fetchStatus.code;
				this.status.ok = fetchStatus.ok;
				this.eventBus.emit("updateStatus", this.status);

				this.eventBus.emit("updateBuyInList");

				this.serverBusy = false;
				this.busyText = "";
				this.closeModal();
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				return false;
			}
		},
		closeModal() {
			this.eventBus.emit("closeCreateOfferModal");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#server-activity-loading {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
	background-color: rgb(0 0 0 / 80%);
	color: #fff;
}

#server-activity-loading {
	text-align: center;
	align-content: center;
}

#create-modal {
	position: fixed;
	display: grid;
	height: 100vh;
	top: 0;
	right: 0;
	bottom: 0;
	left: 20%;
	background-color: rgb(0 0 0 / 75%);
	-webkit-backdrop-filter: blur(6px);
	backdrop-filter: blur(6px);
	z-index: 1;
}

#create-container {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #222;
	color: #fff;
	width: 90%;
	margin: auto;
	padding: 30px;
	border-radius: 12px;
	text-align: center;
}

.loading-message {
	margin-top: 15px;
}

.input-group {
	margin: auto;
	width: 100%;
	min-width: 10em;
}

.label-input {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

.label-input label,
.input-group .playable-label {
	max-width: 10em;
	flex: 0 1 auto;
	text-align: right;
}

.input-group input {
	flex: 0 1 auto;
}

#description,
#casino-name {
	flex: 1 1 auto;
}

fieldset p {
	text-align: left;
}

.button-group {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	margin-top: 10px;
}

@media (min-width: 768px) {
	#create-container {
		width: 50%;
	}

	.input-section fieldset {
		padding: 0.75em;
	}

	.label-input label,
	.input-group .playable-label {
		flex: 1 1 auto;
	}

	.input-group input {
		flex: 1 1 50%;
	}

	.label-input {
		flex-flow: row nowrap;
	}
}
</style>
